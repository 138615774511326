<template>
<vuestic-widget v-if="user">
  <template slot="header">
    <div class="w-100 d-flex justify-content-between align-items-center">
      <div class="title">
        <span>Automations</span>
        <span v-if="showAutomationLimit" class="col availabe-numbers">
          ( {{ availableAutomations }} automation{{ availableAutomations > 1 ? 's' : '' }} available out of {{ user.business.number_of_automations }} )
        </span>
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <button class="btn btn-sm btn-primary mr-2" @click="handleShowImportModal">
          <span>Import Automation <i class="fa fa-upload"></i></span>
        </button>
        <button class="btn btn-primary btn-sm" @click="onNewAutomation">
          <span>New Automation <i class="fa fa-plus"></i></span>
        </button>
      </div>
    </div>
  </template>
  <div class="row">
    <div class="col-md-12">
      <div class="d-flex justify-content-center" v-if="automationFetching">
        <span class="atom-spinner-wrapper">
          <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)"/>
        </span>
      </div>
      <datatable v-else v-bind="this.tableConfig" class="le-datatable"/>
    </div>
  </div>

  <vuestic-modal 
    :isOpen="isOpenModalDelete"
    :hideDefaultActions="true"
    @cancel="closeModalDelete"
    :cancelShown="false"
    :okShown="false"
  >
    <span slot="title" class="text-danger font-weight-bold">Delete Automation</span>
    <p>Are you sure you want to delete this automation <strong>{{automationSelected ? automationSelected.name : ''}}</strong></p>
    <div class="row mt-5">
      <div class="col-md-12 d-flex justify-content-center">
        <button @click="onDeleteAutomation()" class="btn btn-danger" :disabled="automationProcessing">
          <atom-spinner v-if="automationProcessing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
          <span v-else>Delete</span>
        </button>
      </div>
    </div>
  </vuestic-modal>

  <vuestic-modal 
    :isOpen="isShowModalClone"
    :hideDefaultActions="true"
    @cancel="closeModalClone"
    :cancelShown="false"
    :okShown="false"
  >
    <span slot="title" class="font-weight-bold">Clone Automation</span>
    <p>Are you sure you want to clone this automation <strong>{{automationSelected ? automationSelected.name : ''}}</strong></p>
    <div class="row mt-5">
      <div class="col-md-12 d-flex justify-content-center">
        <button @click="handleCloneAutomation()" class="btn btn-primary" :disabled="automationProcessing">
          <atom-spinner v-if="automationProcessing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
          <span v-else>Clone</span>
        </button>
      </div>
    </div>
  </vuestic-modal>

  <vuestic-modal 
    :isOpen="isShowImportModal"
    :hideDefaultActions="true"
    @cancel="handleCloseImportModal"
    :cancelShown="false"
    :okShown="false"
  >
    <span slot="title" class="font-weight-bold">Import Automation</span>

    <text-input name="link" v-model="importLink" label="Link" validate="required"/>

    <div class="row mt-5">
      <div class="col-md-12 d-flex justify-content-center">
        <button @click="handleImportAutomation()" class="btn btn-primary" :disabled="isLoadingImport || !importLink">
          <atom-spinner v-if="isLoadingImport" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
          <span v-else>Import</span>
        </button>
      </div>
    </div>
  </vuestic-modal>

  <vuestic-modal 
    :isOpen="isShowShareModal"
    :hideDefaultActions="true"
    @cancel="closeShareModal"
    :cancelShown="true"
    :okShown="false"
    cancelText="Close"
    cancelClass="btn btn-primary"
  >
    <span slot="title" class="font-weight-bold">Share Automation</span>
    <div>
      <textarea
        ref="shareLink"
        v-model="shareLink"
        cols="30"
        rows="6"
        class="form-control"
        readonly
        @click="copyLink"
      ></textarea>
      <div class="text-right mt-1">
        <a href="javascript:void(0)" @click="copyLink">
          Copy the link
        </a>
      </div>
    </div>
  </vuestic-modal>
  <vuestic-modal
    :isOpen="isOpenModalRestrict"
    @cancel="isOpenModalRestrict = false"
    :cancelShown="true"
    :okShown="false"
    cancelText="Close"
    cancelClass="btn btn-primary"
  >
    <span slot="title" class="text-danger font-weight-bold">No Automations Available</span>
    <div class="d-block text-center">
      <span class="no-install-available-span">You don't have any automations available. If you would like to add a new automation you must delete an existing automation or upgrade your subscription to add additional automations. Please contact us at <a class="link" v-bind:href="'mailto:' + agencyOwnerEmail">{{ agencyOwnerEmail }}</a> If you would like to add an additional automation.</span>
    </div>
  </vuestic-modal>
  <vuestic-modal
    :isOpen="isOpenModalTemplate"
    @cancel="onSkipTemplateModal"
    :cancelShown="true"
    :okShown="false"
    cancelText="Skip"
    cancelClass="btn btn-primary"
  >
    <span slot="title" class="text-primary font-weight-bold">Automation Templates</span>
    <div class="row template-list">
      <div v-for="template in templates" :key="template.id" class="col-md-12 template-box" @click="onSelectTemplate(template.id)">
        <span class="name">{{ template.name }}</span>
        <span v-if="template.desc" class="desc">{{ template.desc }}</span>
      </div>
    </div>
  </vuestic-modal>
  <vuestic-modal
    :isOpen="isOpenModalTemplateLimit"
    @cancel="onSkipTemplateLimitModal"
    :cancelShown="true"
    :okShown="false"
    cancelText="Close"
    cancelClass="btn btn-primary"
  >
    <span slot="title" class="text-primary font-weight-bold">Notification</span>
    <div class="d-block text-center">
      <span class="no-install-available-span">You don't have access to the automation library. You can contact your agency at <a class="link" v-bind:href="'mailto:' + agencyOwnerEmail">{{ agencyOwnerEmail }}</a> to upgrade your plan to gain automation library access.</span>
    </div>
  </vuestic-modal>
</vuestic-widget>
</template>

<script>
  import Vue from 'vue';
  import components from "../../common/tables/comps";
  import moment from 'moment-timezone'
  import { mapState } from 'vuex';

  export default {
    components: { 
    },
    data() {
      return {
        automationSelected: {
        },
        loading: false,
        isOpenModalDelete: false,
        tableConfig: {
          supportBackup: false,
          supportNested: false,
          HeaderSettings: false,
          tblClass: "table-bordered",
          tblStyle: "color: #666",
          pageSizeOptions: [10, 25, 50, 100],
          columns: (() => {
            return [
              {
                title: "ID",
                field: "id",
                label: "ID",
                tdClass: "center",
                sortable: true,
                visible: true,
              },
              {
                title: "Name",
                field: "name",
                label: "Name",
                thClass: "left",
                tdClass: "left",
                sortable: true,
                visible: true
              },
              {
                title: "Start Triggger",
                field: "start_trigger_label",
                label: "Start Triger",
                thClass: "left",
                tdClass: "left",
                sortable: true,
                visible: true
              },
              {
                title: "Active",
                tdComp: 'ActiveToggleTd',
                sortable: false,
                visible: true
              },
              {
                title: "",
                tdComp: 'AutomationMore',
                sortable: false,
                visible: true
              },
            ];
          })(),
          data: [],
          total: 0,
          // selection: [],  //if this is present the row selector shows up..
          summary: {},
          query: {},
          // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
          xprops: {
            eventbus: new Vue()
          }
        },
        submitDataOrg: {
          id: null,
          name: '',
          tags: [],
          scheduled_at: '',
          message: ''
        },
        submitData: {
          id: null,
          name: '',
          tags: [],
          send_at: 'now',
          scheduled_at: '',
          message: ''
        },
        isShowModalClone: false,
        isShowShareModal: false,
        isShowImportModal: false,
        isLoadingImport: false,
        shareLink: null,
        importLink: null,
        isOpenModalRestrict: false,
        isOpenModalTemplate: false,
        isOpenModalTemplateLimit: false,
        templates: [],
      };
    },

    created () {
      this.submitData = Vue.util.extend({}, this.submitDataOrg)

      this.tableConfig.xprops.eventbus.$on('toggle', row => {
        this.toggleActive(row.id, row.active)
      })

      this.tableConfig.xprops.eventbus.$on('onEdit', row => {
        this.$router.push({ name: 'business.automation.edit', params: {id: row.id} });
      })

      this.tableConfig.xprops.eventbus.$on('onClone', row => {
        this.automationSelected = row
        this.isShowModalClone = true
      })

      this.tableConfig.xprops.eventbus.$on('onShare', row => {
        this.automationSelected = row
        this.isShowShareModal = true
        this.shareLink = `${location.origin}/business/automation/${this.automationSelected.unique_id}/share`
      })
      
      
      this.tableConfig.xprops.eventbus.$on('onDelete', row => {
        this.automationSelected = row
        this.isOpenModalDelete = true
      })

      const offset = (this.automationPage - 1 >= 0 ? this.automationPage - 1 : 0) * this.automationPerPage;
      this.tableConfig.query = {
        offset,
        limit: this.automationPerPage,
      }
    },

    watch: {
      automations: {
        handler: function(newValue, oldValue) {
          if (newValue != oldValue)
          {
            this.tableConfig.data = newValue;
          }
        },
        deep: true,
      },
      automationsTotal: {
        handler: function(newValue, oldValue) {
          if (newValue != oldValue)
          {
            this.tableConfig.total = newValue;
          }
        },
        deep: true,
      },
      'tableConfig.query': {
        handler() {
          this.loadAutomations();
        },
        deep: true
      }
    },

    computed: {
      user () {
        return this.$store.state.auth.user
      },

      isTemplate() {
        return this.user && this.user.business && this.user.business.is_template
      },

      isAutomationLibraryEnabled() {
        return this.user && this.user.agency && this.user.agency.automation_library
      },

      showAutomationLimit() {
        return this.user.business.number_of_automations
      },

      availableAutomations() {
        return this.user.business.number_of_automations - this.user.business.automations_count > 0 ? this.user.business.number_of_automations - this.user.business.automations_count : 0
      },

      agencyOwnerEmail () {
        return this.user.agency && this.user.agency.email
      },

      ...mapState('automation', {
        automationFetching: 'fetching',
        automationDidFetch: 'didFetch',
        automationProcessing: 'processing',
        automationPage: 'page',
        automationPerPage: 'perPage',
        automationFilter: 'filter',
        automations: 'data',
        automationsTotal: 'total'
      })
    },
    mounted() {
      this.getAutomationTemplates()
    },
    methods: {
      onSkipTemplateLimitModal() {
        this.isOpenModalTemplateLimit = false
        this.$router.push({ name: 'business.automation.create' });
      },
      onSkipTemplateModal() {
        this.isOpenModalTemplate = false
        this.$router.push({ name: 'business.automation.create' });
      },

      getAutomationTemplates() {
        this.$store.dispatch('automation/getTemplates')
          .then((data) => {
            this.templates = data
          })
          .catch((err) => {
          })
      },

      onSelectTemplate(id) {
        this.isOpenModalTemplate = false
        this.$router.push({ name: 'business.automation.create', query: { templateId: id } });
      },

      onNewAutomation() {
        if (this.showAutomationLimit && !this.availableAutomations)
        {
          this.isOpenModalRestrict = true
        } else {
          if (!this.isTemplate && this.isAutomationLibraryEnabled && this.templates && this.templates.length)
          {
            this.isOpenModalTemplate = true
          } else {
            this.showTemplateLimitModal()
          }
        }
      },

      showTemplateLimitModal() {
        let lastShowAutomationLibraryKey = localStorage.getItem('lastShowAutomationLibraryKey');
        if (!lastShowAutomationLibraryKey)
        {
          lastShowAutomationLibraryKey = moment().subtract(1, 'days').format('MM-DD')
        } 

        if (lastShowAutomationLibraryKey < moment().format('MM-DD'))
        {
          localStorage.setItem('lastShowAutomationLibraryKey', moment().format('MM-DD'))
          this.isOpenModalTemplateLimit = true
        } else {
          this.$router.push({ name: 'business.automation.create' });
        }
      },

      onRefresh() {
        this.loadAutomations()
      },

      toggleActive(id, status) {
        this.$store.dispatch('automation/toggleStatus', {
          id,
          status
        })
      },

      loadAutomations() {
        const { query } = this.tableConfig;
        const page = query.offset ? Math.floor(query.offset /  query.limit) + 1 : 1;
        const param = {
          page,
          per_page: query.limit,
          order: query.order,
          sort: query.sort
        }

        this.$store.dispatch('automation/fetch', param)
      },

      closeModalDelete() {
        this.isOpenModalDelete = false
      },

      closeModalClone() {
        this.isShowModalClone = false
      },

      handleCloneAutomation() {
        this.$store
          .dispatch('automation/clone', this.automationSelected.id)
          .then(() => {
            this.isOpenModalDelete = false;
            this.loadAutomations()
            this.closeModalClone()
          })
          .catch((err) => {
          })
      },

      onDeleteAutomation() {

        this.$store
          .dispatch('automation/delete', this.automationSelected.id)
          .then(() => {
            this.$store.dispatch("auth/getProfile");
            this.isOpenModalDelete = false;
            this.loadAutomations();
          })
          .catch((err) => {
          })
      },

      closeShareModal() {
        this.isShowShareModal = false
      },

      copyLink() {
        this.$refs.shareLink.select()
        this.$refs.shareLink.setSelectionRange(0, 999999)
        document.execCommand('copy')
      },

      handleShowImportModal() {
        this.importLink = null
        this.isShowImportModal = true
      },

      handleCloseImportModal() {
        this.isShowImportModal = false
      },

      handleImportAutomation() {
        const uid = this.importLink.replace(/(.+\/automation\/|\/share)/g, '')
        if (uid)
        {
          this.$router.push({ name: 'business.automation.share', params: {token: uid} });
        }
      }
    },

  };
</script>

<style lang="scss" scoped>
  .template-list {
    max-height: 400px;
    overflow-y: auto;
    padding: 20px 30px;
  }
  .template-box {
    cursor: pointer;
    padding: 1rem 2rem;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
    background: #FFF;
    margin-bottom: 10px;
    border-radius: 10px;
    .name {
      display: block;
      color: #00629f;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 1px;
    }
    .desc {
      font-size: 14px;
    }
    &:hover {
      border: 2px solid #00629f;
    }
  }
</style>
